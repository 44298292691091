import { Injectable } from '@angular/core';
import { BehaviorSubject, debounceTime } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class LoaderService {
    private _isLoading$ = new BehaviorSubject<boolean>(false);
    public isLoading$ = this._isLoading$.pipe(debounceTime(300));

    public show() {
        this._isLoading$.next(true);
    }

    public hide() {
        this._isLoading$.next(false);
    }
}
